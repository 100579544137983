import { ApolloLink, from } from '@apollo/client/core'

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $apollo } = useNuxtApp()
  const config = useSanctumConfig()

  async function initCsrfToken() {
    const config = useSanctumConfig()
    const csrfToken = useCookie('XSRF-TOKEN', { readonly: true })

    if (!csrfToken.value) {
      await $fetch(config.endpoints.csrf, {
        baseURL: config.baseUrl,
        credentials: 'include',
      })
    }
  }

  // Get CSRF token for initial request
  await initCsrfToken()

  // Set up the auth link for crsf

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      // Fix cookie header in SSR
      // https://github.com/nuxt-modules/apollo/issues/312#issuecomment-2007195065
      if (import.meta.server) {
        headers['Cookie'] = useRequestHeader('Cookie') ?? ''
      }

      // Update CSRF token
      headers[config.csrf.header] = useCookie(config.csrf.cookie, { readonly: true }).value
      return { headers }
    })

    return forward(operation)
  })

  // Add the auth link to the default link

  $apollo.defaultClient.setLink(
    from([
      authLink,
      $apollo.defaultClient.link,
    ]),
  )
})
