import type { Config } from 'tailwindcss'
import aspectRatio from '@tailwindcss/aspect-ratio'
import forms from '@tailwindcss/forms'
import defaultTheme from 'tailwindcss/defaultTheme'

export default <Partial<Config>>{
  theme: {
    extend: {
      fontFamily: {
        sans: ['Muoto', ...defaultTheme.fontFamily.sans],
      },
      colors: {
        'primary': '#5A00FF',
        'primary-dark': '#5000E3',
        'error': '#DC2800',
        'red': '#ff0000',
        'green': '#008000',
        'success': '#00A94E',
        'warning': '#E2B100',
      },
      padding: {
        4.5: '1.125rem',
        7.5: '1.875rem',
      },
      margin: {
        4.5: '1.125rem',
      },
      width: {
        7.5: '1.875rem',
      },
      height: {
        7.5: '1.875rem',
      },
      gap: {
        7.5: '1.875rem',
      },
      transitionDuration: {
        DEFAULT: '100ms',
      },
      transitionTimingFunction: {
        DEFAULT: 'ease-out',
      },
    },
  },
  plugins: [aspectRatio, forms],
}
