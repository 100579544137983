<template>
  <p
    class="mb-4 flex text-[15px]"
    :class="textClass"
  >
    <span class="mr-2.5">
      <component :is="icon" />
    </span>{{ message }}
    <!-- L’adresse ou le mot de passe est invalide. -->
  </p>
</template>

<script lang="ts" setup>
import Success from '~/assets/svg/success.svg'
import Error from '~/assets/svg/error.svg'

const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
})

const textClass = computed(() => {
  switch (props.type) {
    case 'success':
      return 'text-success'
    case 'info':
      return 'text-primary'
    case 'error':
      return 'text-error'
  }
})

const icon = computed(() => {
  switch (props.type) {
    case 'success':
      return Success
    case 'info':
      return Success
    case 'error':
      return Error
  }
})
</script>
