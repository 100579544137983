<template>
  <button
    @click.prevent="clickHandler"
  >
    {{ userStore.alert.button.text }}
  </button>
</template>

<script setup lang="ts">
const userStore = useUserStore()
const url = useRequestURL()

function clickHandler() {
  if (userStore.alert.button.action == 'checkout') {
    userStore.createCheckoutSession(url.href, url.href)
  }
  else if (userStore.alert.button.action == 'portal') {
    userStore.createPortalSession(url.href)
  }
  //   else if (userStore.alert.button.action == 'code') {

//   }
}
</script>
