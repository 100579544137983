export default defineAppConfig({
  sanctum: {
    interceptors: {
      onRequest: async (
      ) => {
        if (import.meta.client) {
          const { start } = useLoadingIndicator()
          start()
        }
      },
      onRequestError: async (
      ) => {
        if (import.meta.client) {
          const { finish } = useLoadingIndicator()
          finish()
        }
      },
      onResponse: async (
      ) => {
        if (import.meta.client) {
          const { finish } = useLoadingIndicator()
          finish()
        }
      },
      onResponseError: async (
      ) => {
        if (import.meta.client) {
          const { finish } = useLoadingIndicator()
          finish()
        }
      },
    },
  },
})
