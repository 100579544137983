
// @ts-nocheck


export const localeCodes =  [
  "en",
  "fr"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en.yaml", load: () => import("../lang/en.yaml" /* webpackChunkName: "locale__builds_collection_rh_front_end_lang_en_yaml" */), cache: true }],
  "fr": [{ key: "../lang/fr.yaml", load: () => import("../lang/fr.yaml" /* webpackChunkName: "locale__builds_collection_rh_front_end_lang_fr_yaml" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "language": "en",
      "name": "English",
      "files": [
        "/builds/collection-rh/front-end/lang/en.yaml"
      ]
    },
    {
      "code": "fr",
      "language": "fr-CA",
      "name": "Français",
      "files": [
        "/builds/collection-rh/front-end/lang/fr.yaml"
      ]
    }
  ],
  "defaultLocale": "fr",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "auth/login": {
      "en": "/login",
      "fr": "/connexion"
    },
    "auth/forgot-password": {
      "en": "/forgot-password",
      "fr": "/oubli-mot-de-passe"
    },
    "auth/reset-password": {
      "en": "/reset-password",
      "fr": "/reinitialisation-mot-de-passe"
    },
    "auth/register/individual": {
      "en": "/subscription",
      "fr": "/abonnement"
    },
    "auth/register/[slug]": {
      "en": "/subscription/[slug]",
      "fr": "/abonnement/[slug]"
    },
    "auth/register/trial/trial": {
      "en": "/trial",
      "fr": "/essai"
    },
    "auth/register/trial/parcours-danse": {
      "en": "/parcours-danse",
      "fr": "/parcours-danse"
    },
    "auth/register/trial/promo-fall-2024": {
      "en": "/promo-fall-2024",
      "fr": "/promo-automne-2024"
    },
    "auth/account": {
      "en": "/my-account",
      "fr": "/mon-compte"
    },
    "about": {
      "en": "/about",
      "fr": "/a-propos"
    },
    "movies": {
      "en": "/collection",
      "fr": "/collection"
    },
    "movie/[slug]": {
      "en": "/collection/[slug]",
      "fr": "/collection/[slug]"
    },
    "essays": {
      "en": "/texts",
      "fr": "/textes"
    },
    "essay/[slug]": {
      "en": "/texts/[slug]",
      "fr": "/textes/[slug]"
    },
    "interviews": {
      "en": "/interviews",
      "fr": "/entretiens"
    },
    "video-interview/[slug]": {
      "en": "/video-interviews/[slug]",
      "fr": "/entretiens-video/[slug]"
    },
    "audio-interview/[slug]": {
      "en": "/audio-interviews/[slug]",
      "fr": "/entretiens-audio/[slug]"
    },
    "people": {
      "en": "/directory",
      "fr": "/repertoire"
    },
    "person/[slug]": {
      "en": "/directory/[slug]",
      "fr": "/repertoire/[slug]"
    },
    "terms": {
      "en": "/terms-of-use",
      "fr": "/conditions-utilisation"
    },
    "privacy": {
      "en": "/privacy-policy",
      "fr": "/politique-confidentialite"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "language": "en",
    "name": "English",
    "files": [
      {
        "path": "/builds/collection-rh/front-end/lang/en.yaml"
      }
    ]
  },
  {
    "code": "fr",
    "language": "fr-CA",
    "name": "Français",
    "files": [
      {
        "path": "/builds/collection-rh/front-end/lang/fr.yaml"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
