import { default as about9bijqnReVsMeta } from "/builds/collection-rh/front-end/pages/about.vue?macro=true";
import { default as _91slug_93FPh5Rul7twMeta } from "/builds/collection-rh/front-end/pages/audio-interview/[slug].vue?macro=true";
import { default as accountdE472jA3CLMeta } from "/builds/collection-rh/front-end/pages/auth/account.vue?macro=true";
import { default as forgot_45passwordsjKCbcp5kSMeta } from "/builds/collection-rh/front-end/pages/auth/forgot-password.vue?macro=true";
import { default as loginVJuovgSsl3Meta } from "/builds/collection-rh/front-end/pages/auth/login.vue?macro=true";
import { default as _91slug_93p3ORPrfY3EMeta } from "/builds/collection-rh/front-end/pages/auth/register/[slug].vue?macro=true";
import { default as individualVqt6XIh3RtMeta } from "/builds/collection-rh/front-end/pages/auth/register/individual.vue?macro=true";
import { default as parcours_45dansefOtNMjFsKMMeta } from "/builds/collection-rh/front-end/pages/auth/register/trial/parcours-danse.vue?macro=true";
import { default as promo_45fall_452024gkdnX9oqvMMeta } from "/builds/collection-rh/front-end/pages/auth/register/trial/promo-fall-2024.vue?macro=true";
import { default as trialelqG3J6uakMeta } from "/builds/collection-rh/front-end/pages/auth/register/trial/trial.vue?macro=true";
import { default as reset_45password3bXSx8CnMIMeta } from "/builds/collection-rh/front-end/pages/auth/reset-password.vue?macro=true";
import { default as _91slug_9377wePYMdEeMeta } from "/builds/collection-rh/front-end/pages/essay/[slug].vue?macro=true";
import { default as essaysuJrZmRiQBfMeta } from "/builds/collection-rh/front-end/pages/essays.vue?macro=true";
import { default as indexMCwZRyurasMeta } from "/builds/collection-rh/front-end/pages/index.vue?macro=true";
import { default as interviewsBiL0qxNMpCMeta } from "/builds/collection-rh/front-end/pages/interviews.vue?macro=true";
import { default as _91slug_934XakCgOn8kMeta } from "/builds/collection-rh/front-end/pages/movie/[slug].vue?macro=true";
import { default as moviesAF1bIk9EtSMeta } from "/builds/collection-rh/front-end/pages/movies.vue?macro=true";
import { default as people7Gv96VACYoMeta } from "/builds/collection-rh/front-end/pages/people.vue?macro=true";
import { default as _91slug_93AbJOTUegKuMeta } from "/builds/collection-rh/front-end/pages/person/[slug].vue?macro=true";
import { default as privacyFXihaxu9CoMeta } from "/builds/collection-rh/front-end/pages/privacy.vue?macro=true";
import { default as termshmbir4uVHwMeta } from "/builds/collection-rh/front-end/pages/terms.vue?macro=true";
import { default as _91slug_938h1EUqZ7aMMeta } from "/builds/collection-rh/front-end/pages/video-interview/[slug].vue?macro=true";
export default [
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/builds/collection-rh/front-end/pages/about.vue")
  },
  {
    name: "about___fr",
    path: "/a-propos",
    component: () => import("/builds/collection-rh/front-end/pages/about.vue")
  },
  {
    name: "audio-interview-slug___en",
    path: "/en/audio-interviews/:slug()",
    component: () => import("/builds/collection-rh/front-end/pages/audio-interview/[slug].vue")
  },
  {
    name: "audio-interview-slug___fr",
    path: "/entretiens-audio/:slug()",
    component: () => import("/builds/collection-rh/front-end/pages/audio-interview/[slug].vue")
  },
  {
    name: "auth-account___en",
    path: "/en/my-account",
    meta: accountdE472jA3CLMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/account.vue")
  },
  {
    name: "auth-account___fr",
    path: "/mon-compte",
    meta: accountdE472jA3CLMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/account.vue")
  },
  {
    name: "auth-forgot-password___en",
    path: "/en/forgot-password",
    meta: forgot_45passwordsjKCbcp5kSMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-forgot-password___fr",
    path: "/oubli-mot-de-passe",
    meta: forgot_45passwordsjKCbcp5kSMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login___en",
    path: "/en/login",
    meta: loginVJuovgSsl3Meta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/login.vue")
  },
  {
    name: "auth-login___fr",
    path: "/connexion",
    meta: loginVJuovgSsl3Meta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/login.vue")
  },
  {
    name: "auth-register-slug___en",
    path: "/en/subscription/:slug()",
    meta: _91slug_93p3ORPrfY3EMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/register/[slug].vue")
  },
  {
    name: "auth-register-slug___fr",
    path: "/abonnement/:slug()",
    meta: _91slug_93p3ORPrfY3EMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/register/[slug].vue")
  },
  {
    name: "auth-register-individual___en",
    path: "/en/subscription",
    meta: individualVqt6XIh3RtMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/register/individual.vue")
  },
  {
    name: "auth-register-individual___fr",
    path: "/abonnement",
    meta: individualVqt6XIh3RtMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/register/individual.vue")
  },
  {
    name: "auth-register-trial-parcours-danse___en",
    path: "/en/parcours-danse",
    meta: parcours_45dansefOtNMjFsKMMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/register/trial/parcours-danse.vue")
  },
  {
    name: "auth-register-trial-parcours-danse___fr",
    path: "/parcours-danse",
    meta: parcours_45dansefOtNMjFsKMMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/register/trial/parcours-danse.vue")
  },
  {
    name: "auth-register-trial-promo-fall-2024___en",
    path: "/en/promo-fall-2024",
    meta: promo_45fall_452024gkdnX9oqvMMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/register/trial/promo-fall-2024.vue")
  },
  {
    name: "auth-register-trial-promo-fall-2024___fr",
    path: "/promo-automne-2024",
    meta: promo_45fall_452024gkdnX9oqvMMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/register/trial/promo-fall-2024.vue")
  },
  {
    name: "auth-register-trial-trial___en",
    path: "/en/trial",
    meta: trialelqG3J6uakMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/register/trial/trial.vue")
  },
  {
    name: "auth-register-trial-trial___fr",
    path: "/essai",
    meta: trialelqG3J6uakMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/register/trial/trial.vue")
  },
  {
    name: "auth-reset-password___en",
    path: "/en/reset-password",
    meta: reset_45password3bXSx8CnMIMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___fr",
    path: "/reinitialisation-mot-de-passe",
    meta: reset_45password3bXSx8CnMIMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/auth/reset-password.vue")
  },
  {
    name: "essay-slug___en",
    path: "/en/texts/:slug()",
    component: () => import("/builds/collection-rh/front-end/pages/essay/[slug].vue")
  },
  {
    name: "essay-slug___fr",
    path: "/textes/:slug()",
    component: () => import("/builds/collection-rh/front-end/pages/essay/[slug].vue")
  },
  {
    name: "essays___en",
    path: "/en/texts",
    component: () => import("/builds/collection-rh/front-end/pages/essays.vue")
  },
  {
    name: "essays___fr",
    path: "/textes",
    component: () => import("/builds/collection-rh/front-end/pages/essays.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/collection-rh/front-end/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/builds/collection-rh/front-end/pages/index.vue")
  },
  {
    name: "interviews___en",
    path: "/en/interviews",
    component: () => import("/builds/collection-rh/front-end/pages/interviews.vue")
  },
  {
    name: "interviews___fr",
    path: "/entretiens",
    component: () => import("/builds/collection-rh/front-end/pages/interviews.vue")
  },
  {
    name: "movie-slug___en",
    path: "/en/collection/:slug()",
    component: () => import("/builds/collection-rh/front-end/pages/movie/[slug].vue")
  },
  {
    name: "movie-slug___fr",
    path: "/collection/:slug()",
    component: () => import("/builds/collection-rh/front-end/pages/movie/[slug].vue")
  },
  {
    name: "movies___en",
    path: "/en/collection",
    component: () => import("/builds/collection-rh/front-end/pages/movies.vue")
  },
  {
    name: "movies___fr",
    path: "/collection",
    component: () => import("/builds/collection-rh/front-end/pages/movies.vue")
  },
  {
    name: "people___en",
    path: "/en/directory",
    component: () => import("/builds/collection-rh/front-end/pages/people.vue")
  },
  {
    name: "people___fr",
    path: "/repertoire",
    component: () => import("/builds/collection-rh/front-end/pages/people.vue")
  },
  {
    name: "person-slug___en",
    path: "/en/directory/:slug()",
    component: () => import("/builds/collection-rh/front-end/pages/person/[slug].vue")
  },
  {
    name: "person-slug___fr",
    path: "/repertoire/:slug()",
    component: () => import("/builds/collection-rh/front-end/pages/person/[slug].vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy-policy",
    meta: privacyFXihaxu9CoMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/privacy.vue")
  },
  {
    name: "privacy___fr",
    path: "/politique-confidentialite",
    meta: privacyFXihaxu9CoMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/privacy.vue")
  },
  {
    name: "terms___en",
    path: "/en/terms-of-use",
    meta: termshmbir4uVHwMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/terms.vue")
  },
  {
    name: "terms___fr",
    path: "/conditions-utilisation",
    meta: termshmbir4uVHwMeta || {},
    component: () => import("/builds/collection-rh/front-end/pages/terms.vue")
  },
  {
    name: "video-interview-slug___en",
    path: "/en/video-interviews/:slug()",
    component: () => import("/builds/collection-rh/front-end/pages/video-interview/[slug].vue")
  },
  {
    name: "video-interview-slug___fr",
    path: "/entretiens-video/:slug()",
    component: () => import("/builds/collection-rh/front-end/pages/video-interview/[slug].vue")
  }
]